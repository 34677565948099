import React, { createContext, useContext, useRef, useState } from 'react';

const StateManageContext = createContext();
const StateContextProvider = ({ children }) => {
  const tabsRef = useRef([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [searchUser, setSearchUser] = useState([]);
  const [activeBtn, setActiveBtn] = useState();
  const [dashData, setDashData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(10);
  const [loader, setLoader] = useState(false);

  const contextValue = {
    tabsRef,
    activeTabIndex,
    setActiveTabIndex,
    searchUser,
    setSearchUser,
    activeBtn,
    setActiveBtn,
    dashData,
    setDashData,
    selectedOptions, setSelectedOptions,
    loader, setLoader
  };

  return (
    <StateManageContext.Provider value={contextValue}>
      {children}
    </StateManageContext.Provider>
  );
};

const useManageState = () => {
  const context = useContext(StateManageContext);
  if (!context) {
    throw new Error(
      'useManageState must be used within a StateContextProvider',
    );
  }
  return context;
};

export { useManageState, StateContextProvider };
