/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { toast } from "react-toastify";
import { encryptText } from "../common/config";
import {
  loginUser,
  updatePassword,
  validatePassword,
} from "../redux/auth/authApi";
import { handleLogout } from "../redux/index";
import ChangePasswordDesktop from "./ChangePasswordDesktop";
import ChangePasswordMobile from "./ChangePasswordMobile";
import { ChangePasswordPayload } from "../model/Account";
import IsLoadingHOC from "../component/IsLoadingHOC/IsLoadingHOC";
import { MessageContext } from "../context/MesssageContext";
import { isMobile } from "../lib/common";
const ChangePassword: React.FC<any> = (props) => {
  const user = useAppSelector((state: any) => state.auth);
  const [isMobileView, setIsMobileView] = useState<boolean | null>(null);
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error("SomeComponent must be used within a MessageProvider");
  }
  const { setErrorContent } = context;
  let { setLoading } = props;
  const dispatch = useAppDispatch();
  const [selectedTheme, setSelectedTheme] = useState<string[]>([]);
  let [params, setParams] = useState<ChangePasswordPayload>({
    LevelNumber: 0,
    NewPassword: "",
    ConfirmPassword: "",
    OldPassword: "",
    errorMessage: "",
  });

  const ApplyStyle = (loadStyle: string[]) => {
    var children = document.querySelectorAll(
      "link[dynamic_style='dynamic_style']"
    );
    var childArray = Array.prototype.slice.call(children);
    childArray.forEach(function (child) {
      child.parentNode.removeChild(child);
    });
    loadStyle.map((style) => {
      var element = document.createElement("link");
      element.setAttribute("rel", "stylesheet");
      element.setAttribute("type", "text/css");
      element.setAttribute("dynamic_style", "dynamic_style");
      element.setAttribute("href", `${window.location.origin + "/" + style}`);
      document.getElementsByTagName("head")[0].appendChild(element);
    });
  };

  let error = validatePassword(params.NewPassword);  
  const handleSubmit = (params: ChangePasswordPayload) => {
    if (params.errorMessage !== "") {
      setErrorContent(params.errorMessage);
      return;
    }  else if (params.NewPassword !== params.ConfirmPassword) {
      setErrorContent("Confirm Password is not matched!");
      return;
    } else {
      let changePasswordParams = {
        ...params,
        OldPassword: encryptText(params.OldPassword),
        NewPassword: encryptText(params.NewPassword),
      };
      updatePassword(changePasswordParams)
        .then(
          (res: any) => {
            setLoading(false);
            // dispatch(handleLogout())
            // after changing password it again login to update the user data 
            // ------------------start--------------
            const payload = {
              LoginId: encryptText(user?.userData?.LoginId),
              Password: encryptText(params.NewPassword),
            };
            loginUser(payload, { dispatch })
              .then((res) => {
                setLoading(false);
                window.location.replace("/");
              })
              .catch((err) => {
                setErrorContent(err);
              });
              // ------------------end--------------
          },
          (err: any) => {
            setErrorContent(err);
          }
        )
        .catch((err: any) => {
          setErrorContent(err);
        });
    }
  };

  // Detecting if it's mobile or desktop on component mount
  useEffect(() => {
    const isMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    const isMobileDevice1 = window.matchMedia("(max-width: 767px)").matches;
    setIsMobileView(isMobileDevice || isMobileDevice1);

    if (isMobileDevice || isMobileDevice1) {
      setSelectedTheme(["css/UpdatePasswordMobile.css"]);
    } else {
      setSelectedTheme(["css/UpdatePassword.css", "css/bootstrap_5.min.css"]);
    }
  }, []);

  useEffect(() => {
    if (selectedTheme.length > 0) {
      ApplyStyle(selectedTheme);
    }
  }, [selectedTheme]);

  if (isMobileView === null) {
    return null; 
  }
  
  return (
    <>
      {isMobile ? (
        <ChangePasswordMobile
          params={params}
          setParams={setParams}
          handleSubmit={handleSubmit}
        />
      ) : (
        <ChangePasswordDesktop
          params={params}
          setParams={setParams}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default IsLoadingHOC(ChangePassword);
